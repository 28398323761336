import React, { Suspense, lazy } from 'react';
import './App.css';
import { Switch } from 'react-router';
import { BrowserRouter as Router, Route } from 'react-router-dom';

const StationView = lazy(() => import(/* webpackChunkName: 'StationView' */ './stations/StationView'));
const SlackLandingPage = lazy(() => import(/* webpackChunkName: 'SlackLandingPage' */ './slack/SlackLandingPage'));
const SupportPage = lazy(() => import(/* webpackChunkName: 'SupportPage' */ './support/SupportPage'));

function App() {
  return (
    <Router>
      <div className="app">
        <Suspense fallback={<div>Loading</div>}>
          <Switch>
            <Route path={'/spotify/linked'}>
              <div className="account-linked">
                Your Spotify account is linked
              </div>
            </Route>
            <Route path={'/support'} component={SupportPage} />
            <Route path={'/slack'} component={SlackLandingPage} />
            <Route exact={true} path="/station/:stationId" component={StationView} />
          </Switch>
        </Suspense>
        <footer>
          <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/resonatr/">Instagram</a>
          <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/resonatr/">Facebook</a>
          <a href="/slack">Slack</a>
          <a target="_blank" rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=com.resonatr&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">Google Play</a>
          <a target="_blank" rel="noopener noreferrer" href="https://itunes.apple.com/us/genre/ios-music/id6011?mt=8">App Store</a>
          <a target="_blank" rel="noopener noreferrer" href="https://www.iubenda.com/privacy-policy/8174825">Privacy</a>
          <a href="/support">Support</a>
        </footer>
      </div>
    </Router >
  );
}

export default App;
